import {objectImageElements} from "@/elements";
import OpenSeaDragon from "openseadragon";
import JSZip from "jszip";
import JSZipUtils from "jszip-utils";
import {saveAs} from 'file-saver';

const $ = require('jquery')

export default (function () {

  function formatIiifSource(uri) {
    uri = uri.replace('http://', 'https://')
    return `${uri}/info.json`
  }

  function parseMedia(media) {
    // const media = JSON.parse($(`#${objectImageElements.media}`).text());
    return media.map(i => formatIiifSource(i.mediaUri))
  }

  function setLicenceInfo(pageNumber) {
    const licenceInfo = window.objectImageMedia[pageNumber].mediaLicence
    if (licenceInfo) {
      document.querySelector('.js-licenceInfo').parentElement.style.display = 'block'
      document.querySelector('.js-licenceInfo').innerHTML = licenceInfo
    } else {
      document.querySelector('.js-licenceInfo').parentElement. style.display = 'none'
      document.querySelector('.js-licenceInfo').innerHTML = null
    }
  }

  function initImage() {
    const data = JSON.parse($(`#${objectImageElements.media}`).text())
    const initialPage = window.location.hash ? Number(window.location.hash.slice(1)) - 1 : data.initial_image
    window.objectImage = OpenSeaDragon({
      id: objectImageElements.id,
      crossOriginPolicy: "Anonymous",  // required for download
      tileSources: parseMedia(data.media),
      sequenceMode: true,
      zoomInButton: objectImageElements.zoomInButton,
      zoomOutButton: objectImageElements.zoomOutButton,
      nextButton: objectImageElements.nextButton,
      previousButton: objectImageElements.previousButton,
      fullPageButton: objectImageElements.fullPageButton,
      homeButton: objectImageElements.homeButton,
      initialPage: initialPage,
      toolbar: objectImageElements.toolbar,
    });
    // change url hash when page changes
    window.objectImage.addHandler('page', changePage)
    // set title for nice name when downloading image
    window.objectImageTitle = data.title;
    // save licence Info somewhere so we can access it when page changes
    window.objectImageMedia = data.media;
    // update selected dropdown option
    document.querySelector('.js-selectImage').value = initialPage
    // set licence info for image
    setLicenceInfo(initialPage)
  }

  function goToPage(evt) {
    const page = $(evt.target).data('page')
    if (page !== window.objectImage.currentPage()) {
      window.objectImage.goToPage(page)
    }
  }

  function goToFirstPage() {
    window.objectImage.goToPage(0)
  }

  function goToLastPage() {
    const numberOfPages = window.objectImage.tileSources.length
    const lastPage = numberOfPages - 1
    window.objectImage.goToPage(lastPage)
  }

  function getMediaServerImage() {
    return new JSZip.external.Promise(function (resolve, reject) {
      JSZipUtils.getBinaryContent(window.objectImage.source.id + "/full/max/0/default.jpg", function(err, data) {
          if (err) {
              reject(err);
          } else {
              resolve(data);
          }
      });
    });
  }

  function downloadImage() {
    let title = window.objectImageTitle;
    let title_sanitized = title.replaceAll('"', '').replaceAll(' ', '_');
    let zip = new JSZip();  
    zip.file(title_sanitized + ".jpg", getMediaServerImage());
    zip.generateAsync({type:"blob"})
    .then(function(content) {
        saveAs(content, title_sanitized + ".zip");
    });
  }

  function changePage() {
    const currentPage = window.objectImage.currentPage()
    // set hash in the url
    window.location.hash = Number(currentPage) + 1
    // update the selected page in the dropdown
    document.querySelector('.js-selectImage').value = currentPage
    // update licence info based on current image
    setLicenceInfo(currentPage)
  }

  function selectPage() {
    const page = $(this).val()
    window.objectImage.goToPage(page)
  }

  return {
    init: function () {
      // initialize openseadragon
      initImage();
      // add event listeners
      $('.js-logicalUnit').click(goToPage)
      $('.js-selectImage').change(selectPage)
      $('#first-page-button').click(goToFirstPage)
      $('#last-page-button').click(goToLastPage)
      $('#download-button').click(downloadImage)
    }
  }
}());